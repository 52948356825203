import React from 'react';
import './style.css';

const projects = [
  // serbia
  { country: 'Srbija', city: 'Beograd', manufacturer: 'GE', facility: 'Bolnica "Sveti Sava"' },
  { country: 'Srbija', city: 'Beograd', manufacturer: 'Philips', facility: 'Univerzitetska dečija bolnica' },
  { country: 'Srbija', city: 'Beograd', manufacturer: 'Philips', facility: 'Institut za neurologiju KCS' },
  { country: 'Srbija', city: 'Beograd', manufacturer: 'GE', facility: 'Klinika za digestivne bolesti KCS' },
  { country: 'Srbija', city: 'Beograd', manufacturer: 'Philips', facility: 'Dr Konjović' },
  { country: 'Srbija', city: 'Beograd', manufacturer: 'Canon', facility: 'KBC Zemun' },
  { country: 'Srbija', city: 'Čačak', manufacturer: 'Philips', facility: 'Hipokrat MR' },
  { country: 'Srbija', city: 'Užice', manufacturer: 'GE', facility: 'Zdravstveni centar Užice' },
  { country: 'Srbija', city: 'Sombor', manufacturer: 'Philips', facility: 'Poliklinika Consilium' },
  { country: 'Srbija', city: 'Novi Sad', manufacturer: 'GE', facility: 'Klinički centar Vojvodine' },
  { country: 'Srbija', city: 'Novi Sad', manufacturer: 'Philips', facility: 'Zdravlje Plus' },
  { country: 'Srbija', city: 'Sremska Mitrovica', manufacturer: 'Philips', facility: 'Sirmium Medic' },
  // bosnia
  { country: 'BiH', city: 'Zenica', manufacturer: 'GE', facility: 'Poliklinika "DR Strika"' },
  { country: 'BiH', city: 'Bijeljina', manufacturer: 'Philips', facility: 'Medik - T' },
  { country: 'BiH', city: 'Doboj', manufacturer: 'Philips', facility: 'Dijagnostički centar Dr Brkic' },
  { country: 'BiH', city: 'Brčko', manufacturer: 'Philips', facility: 'Spec. Ordinacija ALFA' },
  { country: 'BiH', city: 'Zvornik', manufacturer: 'GE', facility: 'Opšta bolnica Zvornik' },
  // macedonia
  { country: 'Severna Makedonija', city: 'Skopje', manufacturer: 'GE', facility: 'Klinička bolnica "Sistina"' },
  { country: 'Severna Makedonija', city: 'Skopje', manufacturer: 'GE', facility: 'Gradska bolnica Skopje' },
  { country: 'Severna Makedonija', city: 'Skopje', manufacturer: 'GE 3T', facility: 'Klinička bolnica "Sistina"' },
  { country: 'Severna Makedonija', city: 'Tetovo', manufacturer: 'GE', facility: 'Klinička bolnica Tetovo' },
  { country: 'Severna Makedonija', city: 'Štip', manufacturer: 'GE', facility: 'Klinička bolnica Štip' },
  { country: 'Severna Makedonija', city: 'Petrovec', manufacturer: 'GE', facility: 'Euroitalia' },
  // montenegro 
  { country: 'Crna Gora', city: 'Berane', manufacturer: 'GE', facility: 'Poliklinika Stojanović' },
  { country: 'Crna Gora', city: 'Podgorica', manufacturer: 'GE', facility: 'Hotel Lovćen' },

  { country: 'Moldavija', city: 'Balti', manufacturer: 'Philips', facility: 'Incomed' },

  { country: 'Poljska', city: 'Bielsko-biala', manufacturer: 'Philips', facility: 'Klinika Św. Łukasza' },

  { country: 'Italija', city: 'Trapani', manufacturer: 'Neusoft', facility: 'Multimedica Trapanese' },

  { country: 'Indija', city: 'Karur', manufacturer: 'Philips', facility: 'Cura Healthcare' },
  { country: 'Indija', city: 'Džaipur', manufacturer: 'Philips', facility: 'Cura Healthcare' },


  { country: 'Belorusija', city: 'Minsk', manufacturer: 'Philips', facility: 'Avicenna Medical' },

  { country: 'Bugarska', city: 'Sofija', manufacturer: 'GE', facility: 'Neo Clinic' },

  { country: 'Nemačka', city: 'Föhren', manufacturer: 'Philips', facility: 'Promed' },
  { country: 'Nemačka', city: 'Föhren', manufacturer: 'Philips', facility: 'InHealth mobile magnet' },
  { country: 'Nemačka', city: 'Föhren', manufacturer: 'Philips', facility: 'Calumet mobile magnet' },
  { country: 'Nemačka', city: 'Föhren', manufacturer: 'Philips', facility: 'Smith mobile magnet' },
  { country: 'Nemačka', city: 'Föhren', manufacturer: 'Philips', facility: 'Smith relocatable magnet' },
  { country: 'Nemačka', city: 'Föhren', manufacturer: 'Philips 3T', facility: 'Promed' },

  { country: 'Nemačka', city: 'Schweinfurt', manufacturer: 'Philips', facility: 'Leopoldina' },
  { country: 'Nemačka', city: 'Frankfurt', manufacturer: 'Philips', facility: 'Main Clinic' },
  { country: 'Nemačka', city: 'Mannheim', manufacturer: 'Philips', facility: 'Praxis Dr J.J. Kirsch & Kollegen' },

  { country: 'Nemačka', city: 'Saarbrücken', manufacturer: 'Philips', facility: 'Joint Orthopedic Clinic' },
  { country: 'Nemačka', city: 'Saarbrücken', manufacturer: 'Philips', facility: 'Joint Orthopedic Clinic (zamena magneta)' },

  { country: 'Nemačka', city: 'Bad Sobernheim', manufacturer: 'Philips', facility: 'Sanomed' },

];

const ProjectsTable = () => {
  return (
    <>
      <div className="container">
        <div className="table-wrapper">
          <table>
            <tr>
              <th>Država</th>
              <th>Grad</th>
              <th>MRI</th>
              <th>Ustanova</th>
            </tr>
            <tbody>
              {projects.map(e => {
                return (
                  <tr>
                    <td>{e.country}</td>
                    <td>{e.city}</td>
                    <td>{e.manufacturer}</td>
                    <td>{e.facility}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ProjectsTable;
