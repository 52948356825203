import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { inject, observer } from 'mobx-react';
import SimpleMap from '../components/SimpleMap';
import './projects.css';
import md9 from '../images/md9.png';
import ProjectsTable from '../components/ProjectsTable';
import ReferenceListFile from '../assets/documents/referentna lista piperski.pdf'

const ProjectsPage = (observer(({ store }) => {
  const [activeTab, setActiveTab] = useState('map');
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    store.closeMenu();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true)
    }, 1400)
  }, [])

  const displayMap = () => {
    if (pageLoaded) {
      return <SimpleMap />
    } else {
      return <div style={{ height: '220px', flexDirection: 'column' }} className="loader-wrapper"> <div style={{ width: '61px', height: '61px' }} class="loader"></div><p style={{ marginTop: '20px' }}>Mapa se učitava</p></div>
    }
  }
  return (
    <Layout>
      <Seo title='Projekti' description='Realizovani poslovi kompanije Piperski D.O.O. Poslovi uključuju izgradnju MRI soba, servisiranje MRI kabina i izradu pojedinačnih delova za MRI i RF zaštitu.' />
      <article>
        <div className="page-title page-title-bg-five">
          <h1>Projekti</h1>
        </div>

        <section className='projects-info container'>
          <div className="projects-info-title">
            <h3>Naš rad</h3>
            <div style={{ marginTop: '8px' }} className="title-dash"></div>
          </div>
          <div className="projects-info-text">
            <div className="projects-info-text-inner">
              <p>
                U poslednjih 17 godina uspešno smo montirali preko 30 kabina, i modifikovali/servisirali preko 40.
                Radovi su se izvodili u 11 država i 30 gradova,
                za 5 najvećih proizvođača MRI.<br />
                <div className="projects-info-image-resp">
                  <img alt='MRI tehnički crtež' src={md9} />
                </div>
                <p className="mri-intro">
                  Licencirani smo za rad sa najvećim MRI proizvođačima:
                </p>
                <p className="mri-manufacturers">Phillips,  GE,  Neusoft,  Canon,  Siemens</p>

              </p>
            </div>
            <div className="projects-info-image">
              <img alt='MRI tehnički crtež' src={md9} />
            </div>
          </div>
        </section>

        <section className='projects-bottom'>
          <div style={{ paddingTop: '30px' }} className="projects-info-title">
            <h3>Referenta lista</h3>
            <div className="title-dash"></div>
          </div>
          <div className="projects-bottom-inner">
            <p style={{ fontSize: '19px' }} className="container">
              Možete birati pregled u vidu mape ili liste. Na mapi kliknite na pin kako biste dobili informacije
              o poslovima realizovanim na datoj lokaciji. Referentu listu možete preuzeti <a href={ReferenceListFile} download className="link-color">ovde</a> (manji poslovi nisu navedeni).
            </p>
            <div className="tabs container">
              <div onClick={() => setActiveTab('map')} className={`tab-button ${activeTab === 'map' ? 'active-tab' : ''}`}>
                Vidi mapu
              </div>
              <div onClick={() => setActiveTab('list')} className={`tab-button ${activeTab === 'list' ? 'active-tab' : ''}`}>
                Vidi listu
              </div>
            </div>
          </div>
          {activeTab === 'map' && displayMap()}
          {activeTab === 'list' && <ProjectsTable />}
        </section>
      </article>
    </Layout>
  )
}
))

export default inject('store')(ProjectsPage);
